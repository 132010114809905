import React from 'react';

const Shipping = () => {
    return (
        <div>
            <h2>Shipping</h2>
            <p>Delivery on the way!!!!!</p>
            <p><small>Coming soon....</small></p>
        </div>
    );
};

export default Shipping;