import React, { useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/UserContext';
import './Login.css';

const Login = () => {

    const { signIn } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || '/';

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.target;
        const email = form.email.value;
        const password = form.password.value;
        console.log('email, password :>> ', email, password);

        signIn(email, password).then((result) => {
            const user = result.user;
            console.log('user :>> ', user);
            form.reset();
            navigate(from, { replace: true });
        }).catch((err) => {
            console.error('err', err);
        });
    };

    return (
        <div className='form-container'>
            <h2 className='form-title'>This is login</h2>
            <form onSubmit={handleSubmit}>
                <div className='form-control'>
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" required />
                </div>
                <div className='form-control'>
                    <label htmlFor="password">Password</label>
                    <input type="password" name="password" required />
                </div>
                <input className='btn-submit' type="submit" value="Login" />
            </form>
            <p>New to Ema-John <Link to={'/signup'}>Create a New Account</Link></p>
        </div>
    );
};

export default Login;